<script setup>
import { ref, watch, onMounted } from 'vue';
import { useAuthStore } from '@/core/store/AuthStore';
import { LoadRoles } from '@/core/var/user';

const auth = useAuthStore();

const this_permission = ref(0);
const auth_state = ref(false);
const available_roles = ref([]);


onMounted(() =>{
    //console.log(auth.getUserRole, auth.getAuth(this_permission.value));
    auth_state.value = auth.getAuth(this_permission.value);
    LoadRoles(auth.getUserRole).then(res => {
        available_roles.value = res
    });
    //console.log(auth.getUserRole, auth.getAuth(this_permission.value) ,auth_state.value);
});

watch(this_permission, res =>{
    auth_state.value = auth.getAuth(res);
});

</script>
<template>
    <h1 class="text-xl">Auth Roles Demo</h1>
    <div><label for="perm">Component Permission Level:</label>
    <input type="number" id="perm" name="perm" min="0" max="10" class="w-50"  v-model="this_permission">
</div>

<div>
    Component permission status based on <b>user_role ({{ auth.getUserRole }})</b>: 
    <span class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-md font-medium text-green-800" v-if="auth_state">Access granted</span>
    <span class="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-md font-medium text-red-800" v-else>Forbidden</span>
    </div>

    <div class="my-5">
        <h2 class="text-xl">Adminbutton fot loggedin User</h2>
    <button type="button" v-if="auth_state" class="inline-flex items-center rounded border border-transparent bg-primary-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Func Button for Admin</button>
    <div class="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-md font-medium text-red-800" v-else>Forbidden</div>
</div>
<div class="my-5">
    <h2 class="text-xl">Available Roles for logged in User</h2>
    <ol>
        <li v-for="roles in available_roles" :key="roles.id">{{ roles.name }}({{roles.level}})</li>
    </ol>
</div>
</template>