<script setup>
import { ref } from 'vue'
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    mode: {
        type: String,
        default: 'error'
    },
});
const classes = ref({ text400: "text-red-400", ring600: "ring-red-600", ring500: "focus:ring-red-500" });
const header = ref('Fehler!');
const icon = ref(XCircleIcon);

if (props.mode == 'success') {
    classes.value = { text400: "text-green-400", ring600: "ring-green-600", ring500: "focus:ring-green-500" };
    header.value = 'Das hat geklappt!';
    icon.value = CheckCircleIcon;
} else if (props.mode == 'warn') {
    classes.value = { text400: "text-orange-400", ring600: "ring-orange-600", ring500: "focus:ring-orange-500" };
    header.value = 'Achtung!';
    icon.value = ExclamationCircleIcon;
}
const show = ref(true)
</script>

<template>
    <!-- Global notification live region, render this permanently at the end of the document -->

    <div class="flex w-full flex-col items-center space-y-4 sm:items-end z-50">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <Transition enter-active-class="transform ease-out duration-800 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-800" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div v-if="show" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-gray-50 shadow-lg ring-1 ring-opacity-5" :class="classes.ring600">
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            <icon class="h-6 w-6" :class="classes.text400" aria-hidden="true" />
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm font-medium text-gray-900">{{header}}</p>
                            <p class="mt-1 text-sm text-gray-500">
                                <slot></slot>
                            </p>
                        </div>
                        <div class="ml-4 flex flex-shrink-0">
                            <button type="button" @click="show = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2  focus:ring-offset-2" :class="classes.ring500">
                                <span class="sr-only">Close</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>

</template>