<script>
import { reactive, ref } from 'vue'
import LoadingSpinner from './UI/Loading-Spinner.vue';
import MainToast from './UI/Main-Toast.vue';
import axios from 'axios';


export default {
  name: 'Main-Forgotpw',
  setup() {
    const IN_PRODUCTION = process.env.NODE_ENV;
    const email = ref();
    const state = reactive({
      spinner: false,
      servererror: false,
      buttonstate:false,
      message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
      },
    });
    const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID)
    
    const GetNewPasswordByMail = async () => {
      state.spinner = true;

      let payload = {
        user_email: email.value,
        user_channel: user_channel.value,
      }

      if (payload.user_email && payload.user_channel) {
        await axios
          .post("/dashboard/forgotpw", payload)
          .then((response) => {
            // Error Case
            if (response.data === false) {
              state.message.show = true;
            } else {
              if (response.data.message && response.data.message == 'error') {
                state.message.show = true;
              }
              if (response.data.message && response.data.message == 'user_error') {
                state.message.message = 'Diese E-Mailadresse ist unbekannt.';
                state.message.show = true;
              }
              if (response.data.message && response.data.message == 'success') {
                state.message.message = 'Ihr neues Passwort ist auf dem Weg';
                state.message.messagemode = 'success';
                state.message.show = true;
                state.buttonstate = true;
              }
            }
            window.setTimeout(() => {
              state.message.show = false;
              state.spinner = false
            }, 3000)

          })
          .catch(function (error) {
            state.message.show = true;
            if (!IN_PRODUCTION) {
            if (error.response) {
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              console.log(error.request)
            } else {
              console.log("Error", error.message)
            }
            window.setTimeout(() => {
              state.spinner = false
              state.message.show = false;
            }, 6000)

            }
          })
      }
    }
    return {
      state,
      email,
      GetNewPasswordByMail
    };
  },
  components: { LoadingSpinner, MainToast },
}
</script>

<template>

  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="../assets/SnappyIcon.svg" alt="Your Company" />
      <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Passwort vergessen?</h2>
      <p class="mt-2 text-center text-sm text-gray-600 px-5">
        Sie haben Ihr Passwort verlegt? Geben Sie hier Ihre E-Mail Adresse ein und lassen Sie sich ein neues zu schicken.
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" v-on:submit.prevent="GetNewPasswordByMail">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">E-Mail</label>
            <div class="mt-1">
              <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm" />

            </div>
          </div>
          <div class="text-sm">
            <router-link to="/" class="font-medium text-primary-600 hover:text-primary-500">Zurück zum Login</router-link>
          </div>
          <div>
            <button type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-100 focus:ring-offset-1 disabled:opacity-50 disabled:bg-primary-600" :disabled="state.buttonstate">
              <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> Neues Passwort per E-Mail
            </button>
          </div>
        </form>


      </div>
    </div>
  </div>
      <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6">
  <MainToast :mode="state.message.messagemode" v-if="state.message.show">{{ state.message.message }}</MainToast></div>
</template>
