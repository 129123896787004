<template>
    <div class="w-2/3 md:w-1/2 rounded-xl bg-dark-04 text-white px-4 py-7 text-center m-4 shadow-md">
        <h4 class="mb-2 text-base font-bold">
            Get Job Information <br>Into Your Inbox!
        </h4>
        <p class="text-dark-01 text-xs mb-6">By input your email address we will send you the latest information</p>
        <a href="#" class="bg-white rounded-[30px] transition-all hover:text-gray-500 px-7 py-2 flex items-center gap-2 w-fit mx-auto hover:bg-primary text-dark-03 text-xs font-semibold">
            Subscribe
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.2" d="M10.12 3.953 14.167 8l-4.047 4.047M2.833 8h11.22"></path>
            </svg>
        </a>
    </div>
</template>