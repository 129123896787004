<template>
    <div>
        <input type="text" v-model="search" placeholder="Search..." class="w-64 rounded-md px-3 py-2 mb-3 focus:outline-none focus:ring border-primary-400">
        <div class="hidden md:block">
            <table class="w-full">
                <thead>
                    <tr>
                        <th @click="sortBy('episode_id')" :class="sortColumn === 'episode_id' ? sortClasses : ''" class="cursor-pointer">Episode ID</th>
                        <th @click="sortBy('title')" :class="sortColumn === 'title' ? sortClasses : ''" class="cursor-pointer">Title</th>
                        <th @click="sortBy('director')" :class="sortColumn === 'director' ? sortClasses : ''" class="cursor-pointer">Director</th>
                        <th @click="sortBy('producer')" :class="sortColumn === 'producer' ? sortClasses : ''" class="cursor-pointer">Producer</th>
                        <th @click="sortBy('release_date')" :class="sortColumn === 'release_date' ? sortClasses : ''" class="cursor-pointer">Release Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(movie, index) in sortedMovies" :key="index">
                        <td>{{ movie.episode_id }}</td>
                        <td>{{ movie.title }}</td>
                        <td>{{ movie.director }}</td>
                        <td>{{ movie.producer }}</td>
                        <td>{{ movie.release_date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>


    <div class="sm:hidden">
        <div class="space-y-2">
            <div v-for="(movie, index) in sortedMovies" :key="index" class="divide-y divide-dashed py-10">
                <h2 class="text-2xl font-bold">{{ movie.title }}</h2>
                <div class="flex flex-col space-y-1">
                    <span class="font-bold">Director:</span>
                    <span>{{ movie.director }}</span>
                </div>
                <div class="flex flex-col space-y-1">
                    <span class="font-bold">Producer:</span>
                    <span>{{ movie.producer }}</span>
                </div>
                <div class="flex flex-col space-y-1">
                    <span class="font-bold">Release Date:</span>
                    <span>{{ movie.release_date }}</span>
                </div>
                <div class="flex flex-col space-y-1">
                    <span class="font-bold">Opening Crawl:</span>
                    <span>{{ movie.opening_crawl }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import axios from 'axios'

const movies = ref([])
const search = ref('')
const sortColumn = ref('')
const sortDirection = ref('asc')
const sortClasses = 'bg-gray-200'
const apiURL = 'https://swapi.dev/api/films/'

axios.get(apiURL).then(response => {
    movies.value = response.data.results
})

const sortedMovies = computed(() => {
    let sorted = [...movies.value]

    if (sortColumn.value) {
        sorted = sorted.sort((a, b) => {
            const propA = a[sortColumn.value]
            const propB = b[sortColumn.value]

            if (propA < propB) {
                return sortDirection.value === 'asc' ? -1 : 1
            } else if (propA > propB) {
                return sortDirection.value === 'asc' ? 1 : -1
            } else {
                return 0
            }
        })
    }

    if (search.value) {
        sorted = sorted.filter(movie => {
            return movie.title.toLowerCase().includes(search.value.toLowerCase())
        })
    }

    return sorted
})

function sortBy(column) {
    if (column === sortColumn.value) {
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'
    } else {
        sortColumn.value = column
        sortDirection.value = 'asc'
    }
}
</script>
