// @deprecated


import axios from 'axios'
import Logout from './Logout'
import 'axios'


const getAuth = async () => {

    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;


    await axios
        .get("/dashboard/auth")
        .then((response) => {

            if (response.data === false) {
                Logout();
                return false;
            } else {
                if (response.data.message === 'noauth' || response.data.message === 'expired') {
                    Logout();
                    return false;
                }
            }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log("Error", error.message)
            }
            Logout();
            return false;
        })
}
export default getAuth;
