<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 items-center px-4">
                <img class="h-8 w-auto" :src="getLogo()" alt="" />
                <div class="ml-4 text-gray-900">
                  <router-link to="/dashboard" exact-active-class="none">{{ appmode.fullname }}</router-link>
                  <div class="text-xs font-thin text-ellipsis overflow-hidden">{{ appmode.appname }}</div>
                </div>
              </div>
              <div class="mt-5 h-0 flex-1 overflow-y-auto">
                <nav class="space-y-1 px-2">
                  <MainNavigation />
                </nav>

              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>


    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col bg-white">
        <div class="flex h-16 flex-shrink-0 items-center bg-white px-4">

          <img class="h-8 w-auto animate-shake" :src="getLogo()" alt="" />
          <div class="ml-4 text-gray-900">
            <router-link to="/dashboard" exact-active-class="none">{{ appmode.fullname }}</router-link>
            <div class="text-xs font-thin text-ellipsis overflow-hidden">{{ appmode.appname }}</div>
          </div>
        </div>



        <div class="flex flex-1 flex-col overflow-y-auto">
          <nav class="flex-1 space-y-1 px-2 py-4">
            <MainNavigation />
          </nav>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:pl-64">
      <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
        <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex flex-1 justify-between px-4">
          <div class="flex flex-1">
            <!-- <form class="flex w-full md:ml-0" action="#" method="GET">
              <label for="search-field" class="sr-only">Search</label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                  <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
                </div>
                <input id="search-field" class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm" placeholder="Search" type="search" name="search" />
              </div>
            </form> -->
          </div>


          <div class="ml-4 flex items-center md:ml-6">

            <div>
              <LoadingSpinner v-show="Simplestore.spinner"></LoadingSpinner>
            </div>

            <admin-status v-show="adminstatus" />

            <!-- <button type="button" class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">

              <div>

                <MenuButton class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                  <span class="sr-only">Open user menu</span>
                  <span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100 border-2 border-gray-300">
                    <svg class="h-full w-full text-gray-500  " fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                  <router-link :to="item.href" active-class="bg-gray-200" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">{{ item.name }}</router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <!-- <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
          </div> -->
          <div class="mx-auto w-full px-4 sm:px-6 md:px-8">

            <router-view></router-view>

            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import LoadingSpinner from './UI/Loading-Spinner.vue';
import AdminStatus from './UI/Admin-Status.vue';
import { onBeforeMount, ref, onMounted } from 'vue';

//import getAuth from '@/core/var/auth';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,

  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue'

import {
  Bars3Icon,
  CalendarIcon,
  HomeIcon,
  ArrowUpIcon,
  ArrowUpOnSquareIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  UserGroupIcon,
  XMarkIcon,
  InboxIcon,
  UsersIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  ArrowLeftOnRectangleIcon,
  ArrowDownTrayIcon,
  EnvelopeIcon,
  BellIcon,
  EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon,
} from '@heroicons/vue/24/outline'
import { RouterMap } from '@/core/var/router';
import Simplestore from '@/core/var/Simplestore'
import IconMap from '@/core/router/map'
import CoreRouter from '@/core/router/router';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import MainNavigation from '@/core/components/UI/Main-Navigation.vue';
import DataHub from '@/core/components/User/Admin/Admin-Datahub.vue';
import {
  Square3Stack3DIcon
} from '@heroicons/vue/24/outline';
import {
  RouteAdd,
  RouteAddtoDashboard,
  DashboardAddLink
} from '@/core/var/router';

const auth = useAuthStore();

const adminstatus = ref(auth.getAuth(0));


const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;

const getLogo = () => {
  return require(`../../customers/${appmode.customer}/assets/${appmode.assets.logo}`);
}

const userNavigation = [
  { name: 'Profil', href: '/dashboard/profile' },
  //{ name: 'Einstellungen', href: '/dashboard/settings' },
  { name: 'Logout', href: '/logout' },
]
const sidebarOpen = ref(false);
const authRouteMapper = {
  userrole: auth.getUserRole,
  mapping: [{ name: 'Data Hub', auth: 0 }]
}
</script>