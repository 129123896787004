<template>
    <h1 class="text-3xl">{{ appmode.appname }}  - UI Elements</h1>
    <div class="mt-5">

        <div class="myrow">
            <h5> Tabs</h5>
            <div class="w-full max-w-md px-2 py-16 sm:px-0">
                <TabGroup>
                    <TabList class="flex space-x-1 rounded-xl bg-primary-600 p-1">
                        <Tab v-for="category in Object.keys(categories)" as="template" :key="category" v-slot="{ selected }">
                            <button :class="[
                                'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2',
                                selected
                                    ? 'bg-white shadow text-primary-600'
                                    : 'text-white hover:bg-white/[0.12] hover:text-white',
                            ]">
                                {{ category }}
                            </button>
                        </Tab>
                    </TabList>

                    <TabPanels class="mt-2">

                        <TabPanel v-for="(posts, idx) in Object.values(categories)" :key="idx" :class="[
                            'rounded-xl bg-white p-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2',
                        ]">
                            <ul>
                                <li v-for="post in posts" :key="post.id" class="relative rounded-md p-3 hover:bg-gray-100">
                                    <h3 class="text-sm font-medium leading-5">
                                        {{ post.title }}
                                    </h3>

                                    <ul class="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                        <li>{{ post.date }}</li>
                                        <li>&middot;</li>
                                        <li>{{ post.commentCount }} comments</li>
                                        <li>&middot;</li>
                                        <li>{{ post.shareCount }} shares</li>
                                    </ul>

                                    <a href="#" :class="[
                                        'absolute inset-0 rounded-md',
                                        'ring-primary-400 focus:z-10 focus:outline-none focus:ring-2',
                                    ]" />
                                </li>
                            </ul>
                        </TabPanel>
                    </TabPanels>
                </TabGroup>
            </div>

        </div>


        <div class="myrow">
            <h5> Buttons</h5>
            <p>sm, green, icon:
                <RouterLink to="/dashboard">
                    <main-button :icon="ArchiveBoxIcon" size="sm" color="green" textcolor="gray">Zum Dashbaord</main-button>
                </RouterLink>
            </p>
            <p> xl, primary, icon, emitter <main-button :icon="EnvelopeIcon" size="xl" color="primary" @clicked="buttonClicked">Mailbox</main-button></p>
            <p> md, gray, emitter <main-button size="md" color="gray" @clicked="buttonClicked">Click</main-button></p>
        </div>




        <div class="myrow">
            <h5>Toasts</h5>
            <p class="">Toast Triggers</p>
            <main-button size="sm" color="primary" @click="triggerToast">Trigger Toast</main-button>
            <main-button size="sm" color="primary" @click="triggerToast2(10)">Trigger Toast with Timer (10s)</main-button>
            <main-button size="sm" color="primary" @click="triggerToast2(2)">Trigger Toast with Timer (2s)</main-button>
            <p class="">Toast Designs</p>
            <p>
                <main-toast>Foo Fehler Lorem ipsum dolor sit amet consectetur adipisicing elit.</main-toast>
            </p>
            <p>
                <main-toast mode="success">Hat geklappt!</main-toast>
            </p>
            <p>
                <main-toast mode="warn">Achtung Warnung</main-toast>
            </p>
        </div>


        <div class="myrow">
            <h5>Modals</h5>
            <main-button size="sm" color="primary" @click="triggerModal">Trigger Modal</main-button>

            <main-modal :open="modalopen" title="Foo" button="Okk" button2="Weg damit" @returnValue="ReturnModal" @open="getModalStatus">
                True oder false?
            </main-modal>
        </div>
    </div>

    <div class="myrow">
        <h5>Alerts (tbd)</h5>
        <MainAlert>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus labore officia deserunt ipsam quia ea minima, eos quam ipsa, esse consequatur fuga debitis exercitationem aut autem. Commodi dignissimos qui eligendi!</MainAlert>
    </div>

    <div class="myrow">
        <h5>Infobox (tbd)</h5>
        <MainInfobox></MainInfobox>
    </div>

    <div class="myrow">
        <h5>Simple Grid</h5>
        <SimpleGrid />
    </div>

        <div class="myrow">
            <h5>Navigation</h5>
            <div class="w-1/4 m-6"><MainNavigation /></div>
        </div>



    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <main-toast v-show="showtoast">Fehler</main-toast>
    </div>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <main-toast v-show="showtoast2" mode="warn">Warning closed in {{ countdown }}</main-toast>
    </div>

    <MainGenPasswd></MainGenPasswd>
</template>
<script setup>

import { ref } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import MainButton from '@/core/components/UI/Main-Button.vue'
import { EnvelopeIcon, ArchiveBoxIcon } from '@heroicons/vue/20/solid';
import MainAlert from '@/core/components/UI/Main-Alert.vue';
import MainInfobox from '@/core/components/UI/Main-Infobox.vue';
import MainToast from '@/core/components/UI/Main-Toast.vue';
import MainModal from '@/core/components/UI/Main-Modal.vue';
import SimpleGrid from '@/core/components/UI/Main-SimpleGrid.vue';
import MainNavigation from '@/core/components/UI/Main-Navigation.vue';
import { useCustomerStore } from '@/core/store/CustomerStore';
import MainGenPasswd from '@/core/components/Main-GenPasswd.vue';

const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
//console.log(appmode);

const showtoast = ref(false);
const showtoast2 = ref(false);
const countdown = ref(0);
const modalopen = ref(false);

const categories = ref({
    Recent: [
        {
            id: 1,
            title: 'Does drinking coffee make you smarter?',
            date: '5h ago',
            commentCount: 5,
            shareCount: 2,
        },
        {
            id: 2,
            title: "So you've bought coffee... now what?",
            date: '2h ago',
            commentCount: 3,
            shareCount: 2,
        },
    ],
    Popular: [
        {
            id: 1,
            title: 'Is tech making coffee better or worse?',
            date: 'Jan 7',
            commentCount: 29,
            shareCount: 16,
        },
        {
            id: 2,
            title: 'The most innovative things happening in coffee',
            date: 'Mar 19',
            commentCount: 24,
            shareCount: 12,
        },
    ],
    Trending: [
        {
            id: 1,
            title: 'Ask Me Anything: 10 answers to your questions about coffee',
            date: '2d ago',
            commentCount: 9,
            shareCount: 5,
        },
        {
            id: 2,
            title: "The worst advice we've ever heard about coffee",
            date: '4d ago',
            commentCount: 1,
            shareCount: 2,
        },
    ],
})
const buttonClicked = res => alert(res);

const triggerToast = () => {
    showtoast.value = true;
}

const triggerToast2 = (seconds = 5) => {

    countdown.value = seconds;
    showtoast2.value = true;

    const timer = setInterval(() => {
        if (countdown.value > 0) {
            countdown.value = countdown.value - 1;
        } else {
            clearInterval(timer);
        }
    }, 1000);

    window.setTimeout(() => {
        clearInterval(timer);
        showtoast2.value = false
    }, seconds * 1000);
}

const triggerModal = () => {
    modalopen.value = true;
}
const ReturnModal = res => {
    alert(res);
}

const getModalStatus = val => {
    modalopen.value = val;
}
</script>


<style scoped>
.myrow {
    @apply py-1 mb-2 text-base
}

.myrow h5 {
    @apply text-xl
}
</style>