<template>
    <router-link v-for="item in RouterMap(mapper.default, CoreRouter, authRouteMapper)" :key="item.name" :to="item.href" :class="[item.current ? 'bg-primary-500 text-white' : 'text-gray-600 hover:bg-primary-300 hover:text-gray-50', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
        <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-white-400 group-hover:text-white', 'mr-3 h-6 w-6']" aria-hidden="true" />
        {{ item.name }}
    </router-link>
</template>

<script setup>
import { RouterMap } from '@/core/var/router';
import IconMap from '@/core/router/map';
import { useAuthStore } from '@/core/store/AuthStore';
import CoreRouter from '@/core/router/router';

const mapper = require(`/src/customers/${process.env.VUE_APP_NEURAXIS_CHANNEL_ID}/router/map.js`);
//console.log(mapper.default);

const auth = useAuthStore();

const authRouteMapper = {
    userrole: auth.getUserRole,
    mapping: [{ name: 'Data Hub', auth: 0 }]
}
</script>

<style scoped>
.router-link-active{
   @apply  text-white
}
</style>