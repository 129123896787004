/** 
 * Index for default customer 
*/
import { createApp } from 'vue'
import App from '@/App.vue'
import {
    Square3Stack3DIcon
} from '@heroicons/vue/24/outline';
import CoreRouter from '@/core/router/router';
import AdminUseradd from '@/core/components/User/Admin/Admin-Useradd.vue';
import AdminUsers from '@/core/components/User/Admin/Admin-Users.vue';
import AuthRolesDemo from './components/Auth-RolesDemo.vue';
import UIDemo from '@/customers/neuraxis/components/Main-UI.vue'
import DataHub from '@/core/components/User/Admin/Admin-Datahub.vue';
import { RouteAdd, 
    RouteAddtoDashboard, 
    DashboardAddLink } from '@/core/var/router';
const customer = require('./config/config');
import '@/core/var/axios';
import '@/core/assets/primevue/neuraxis.css';

import { useCustomerStore } from '@/core/store/CustomerStore';

import { createPinia } from 'pinia';
const pinia = createPinia();

//optional
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import './assets/font.css';

RouteAddtoDashboard({ name: 'Add-User', position: 'dashboard-left' }, AdminUseradd, CoreRouter);
RouteAddtoDashboard({ name: 'Users', position: 'dashboard-left' }, AdminUsers, CoreRouter);
//RouteAddtoDashboard({ name: 'DataHub', position: 'dashboard-left' }, DataHub, CoreRouter);

// Add Full Dashboard & Link to Sidebar:
const data_hub_link = 'datahub';
RouteAdd(data_hub_link, DataHub, CoreRouter);
DashboardAddLink({ name: 'Data Hub', href: `/${data_hub_link}`, icon: Square3Stack3DIcon, order: 10 });
RouteAddtoDashboard({ name: 'AuthRolesDemo', position: 'dashboard-left' }, AuthRolesDemo, CoreRouter);
RouteAddtoDashboard({ name: 'UIDemo', position: 'dashboard-left' }, UIDemo, CoreRouter);


const neuraxisApp = createApp(App);

neuraxisApp.use(pinia);
const customerStore = useCustomerStore();
customerStore.setCustomer(customer);


neuraxisApp.use(CoreRouter);

//optional
neuraxisApp.use(PrimeVue);
neuraxisApp.use(ToastService);
neuraxisApp.use(ConfirmationService);
neuraxisApp.directive('Tooltip', Tooltip);

neuraxisApp.mount('#app');
