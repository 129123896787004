import { capitalizeFirstLetter } from '@/core/var/tools';
import { RouterAdditionalLinks } from '../router/links';
import { HomeIcon} from '@heroicons/vue/24/outline';

const RouteAdd = (name, component, Router) => {
    Router.addRoute({ name: name, path: `/${name}`, component: component });
    Router.replace(`/${name}`);
}
export {RouteAdd};

const DashboardAddLink = (config) => {
    RouterAdditionalLinks.push({name:config.name, href:config.href, icon: config.icon, order:config.order });
}
export { DashboardAddLink };

const RouteAddtoDashboard = (config, component, Router) => {
    Router.addRoute('dashboard', { name:config.name, path: config.name, component: component, meta:{position:config.position} })
    Router.replace(`/dashboard/${config.name}`);
}
export { RouteAddtoDashboard };


//** add child Route to any given Dashboard */
const DashboardAddChild = (config, component, Router) => {
    Router.addRoute(config.dashboardname, { name: config.name, path: config.name, component: component, meta: { position: config.position } })
    const repl = `/${config.dashboardname}/${config.name}`;
    Router.replace(repl);  
}
export { DashboardAddChild };


const RouteRemove = (name, Router) => {
    if(name === 'dashboard'){
        console.error('Error: The Dashboard route cannot be removed.');
        return false;
    }else{
        Router.removeRoute(name) 
    }
}
export { RouteRemove };

const RouteRemoveFromDashboard = (config, component, Router) => {
    Router.addRoute('dashboard', { path: config.name, component: component, meta: { position: config.position } })
    Router.replace(`/dashboard/${config.name}`);
}
export { RouteRemoveFromDashboard };


const RouterMap = (IconMap, Router, authMapper) => {

    const findRoute = (arr, search) => {
        return arr.find(({ name }) => name === search);
    }

    const res = [];
    Router.getRoutes().forEach(route => {
        
        
        if (typeof route.name !== 'undefined' && route.meta.position == 'dashboard-left') {
            const foundRoute = findRoute(IconMap, route.name);
            if (foundRoute !== undefined) {
                res.push(foundRoute);
            }else {
                res.push({ name: capitalizeFirstLetter(route.name), href: route.path, icon: HomeIcon, current: false });
            }
        }


    });

    RouterAdditionalLinks.forEach( route =>{
        res.push({ name: capitalizeFirstLetter(route.name), href: route.href, icon: route.icon, order:route.order, current: false });
    });

    //addtional authentication
    if (authMapper != undefined){

        const findauthMapping = (arr, search) => {
            return arr.find(({ name }) => name === search);
        }
        
        res.forEach((route, index, obj) =>{

            const mapping = findauthMapping(authMapper.mapping, route.name);

            if (mapping != undefined) {
                if (authMapper.userrole > mapping.auth) {
                    console.log(`no auth, removing ${route.name}`);
                    obj.splice(index, 1);
                } 
            }
        });
    }

    res.sort((a, b) => (a.order > b.order) ? 1 : -1);

    return res;
}
export {RouterMap}
