import axios from 'axios'
import 'axios'


const GetUserData = async payload => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.post("/dashboard/get-user-data", payload);

    return request
        .then(result => { 
            return result; 
        })
        .catch(error => { return Promise.reject(error); });
}
export { GetUserData };


const SetUserData = async payload => {

    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.post("/dashboard/set-user-data", payload);

    return request
        .then(result => { return result; })
        .catch(error => { return Promise.reject(error); });
}
export { SetUserData };


const GetUsers = async payload => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.get("/dashboard/get-users", payload);

    return request
        .then(result => {

            return result;
        })
        .catch(error => { return Promise.reject(error); });
}
export { GetUsers };


const DeleteUser = async payload => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.post("/dashboard/delete-user", payload);

    return request
        .then(result => {

            return result;
        })
        .catch(error => { return Promise.reject(error); });
}
export { DeleteUser };



const RemoveRoleByUserRole = (arr, user_role) => {
    var filtered = arr.filter(function (value, index, arr) {

        return value.level >= user_role;
    });
    return filtered;
}


const LoadRoles = async (user_role) => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;
 
    const request = axios.get("/dashboard/get-roles");

    return request
        .then(result => {
            const res = {data:RemoveRoleByUserRole(result.data, user_role)};
            return res.data;
        })
        .catch(error => { return Promise.reject(error); });
}
export {LoadRoles};


const AuthorizeUser = (roles, user_data) => {
    return true;
}
export { AuthorizeUser };
