<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-50">
                                    <QuestionMarkCircleIcon class="h-6 w-6 text-primary-800" aria-hidden="true" />
                                </div>
                                <div class="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">{{props.title}}</DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">
                                            <slot />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6" :class="`${buttongrid_classes}`">
                                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="buttonClick(true)">{{props.button}}</button>

                                <button type="button" v-if="props.button2" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="buttonClick(false)" ref="cancelButtonRef">{{props.button2}}</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref, watch} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    open: Boolean,
    data: Object,
    title: String,
    button: String,
    button2: String
});



const emit = defineEmits(['open','returnValue']);
const open = ref(false);
const buttongrid_classes = ref(`sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3`);

const buttonClick = (val) => {
    open.value = false;
    emit('returnValue', val);
} 

watch(() => props.open, val => {
    if (props.button2 == undefined || props.button2 === ''){
        buttongrid_classes.value = "";
    }
    open.value = val;
});
watch(() => open.value, val => {
    emit('open', val);
});
</script>