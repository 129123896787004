<template>
    <h1 class="text-xl font-semibold text-gray-900">User Admin</h1>

    <div v-if="state.spinner" class="flex justify-center">
        <LoadingSpinner size="12" class="my-5" v-if="state.spinner" />
    </div>

    <div v-else>
        <DataTable :value="users" editMode="cell" @cell-edit-complete="onCellEditComplete" sortField="user_id" :sortOrder="-1" class="min-w-full divide-y divide-gray-300" responsiveLayout="scroll" :paginator="true" :rows="10">


            <Column field="user_email" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Email">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_firstname" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Vorname">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_lastname" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Nachname">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_street" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Straße">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_plz" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="PLZ">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_city" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Ort">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_mobile" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Tel">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" autofocus></InputText>
                </template>
            </Column>
            <Column field="user_land" :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Land">
                <template #editor="{ data, field }">
                    <select id="user_land" name="user_land" autocomplete="user_land" v-model="data[field]" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm" >
                        <option value="Schweiz">Schweiz</option>
                        <option value="Deutschland">Deutschland</option>
                        <option value="Österreich">Österreich</option>
                    </select>
                </template>
            </Column>
            <Column field="user_role" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Role">
                <template #body="slotProps">
                     {{ GetRoleName(slotProps.user_roles, user_roles) }} 
                </template>
                <template #editor="{ data, field }">
                    <select id="user_roles" name="user_roles" autocomplete="user_roles" v-model="data[field]" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm" >
                        <option v-for="role in user_roles" v-bind:key="role.id" :value="role.id">{{ role.name }}</option>
                    </select>
                </template>
            </Column>

            <Column :sortable="true" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" header="Löschen">
                <template #body="slotProps">
                    <button type="button" :data-del-id="slotProps.data.user_id" @click="openModal(slotProps.data, slotProps)" class="inline-flex items-center rounded-full border border-transparent bg-red-600 p-1 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-0 focus:ring-red-100 focus:ring-offset-0">
                        <XMarkIcon class="h-3 w-3" aria-hidden="true"></XMarkIcon>
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <MainToast :mode="state.message.messagemode" v-if="state.message.show">{{ state.message.message }}</MainToast>
    </div>


    <main-modal :open="state.openmodal" title="Benutzer" button="Ok" button2="Abbruch" @open="getModalStatus" @returnValue="DeleteUserRow">
        Benutzer wirklich entfernen?
    </main-modal>
</template>

<script setup>
import axios from 'axios';
import { ref, reactive, onBeforeMount, inject } from 'vue';
import DataTable from 'primevue/datatable/sfc';
import Column from 'primevue/column/sfc';
import InputText from 'primevue/inputtext/sfc';
import MainToast from '@/core/components/UI/Main-Toast.vue';
//import MainAlert from '../../UI/Main-Alert.vue';
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { Message } from '@/core/var/Message';
import { GetUsers, DeleteUser, LoadRoles } from '@/core/var/user';
import MainModal from '@/core/components/UI/Main-Modal.vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '@/core/store/AuthStore';

const auth = useAuthStore();
const userdata = auth.getUserData;



const user_roles = ref({});
const users = ref();
const state = reactive({
    spinner: false,
    message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
    },
    currentDataSet: {}
});

const GetRoleName = (role_id, roles) => {
    const res = roles.filter(obj => {
        if (obj.id === role_id) {
            return obj.name;
        }
    });
    if (res[0] !== undefined){
        return res[0].name;
    }
        
}
//const userdata = inject('userdata');

onBeforeMount(async () => {
    Simplestore.spinner = true;
    state.spinner = true;

    Promise.all([GetUsers(), LoadRoles(auth.getUserRole)]).then(res => {
        //console.log(res[0].data, res[1]);
        users.value = res[0].data;
        user_roles.value = res[1];
        Simplestore.spinner = false;
        state.spinner = false;
    });

});


const onCellEditComplete = (event) => {
    let { data, newValue, field } = event;
    //tbd
    switch (field) {
        // case 'user_email':
        //     console.log(data);
        //     break;
        // case 'user_firstname':
        //     console.log(data);
        //     break;
        // case 'user_lastname':
        //     console.log(data);
        //     break;
        // case 'user_streeet':
        //     console.log(data);
        //     break;
        // case 'user_plz':
        //     console.log(data);
        //     break;
        default:
            if (typeof newValue != 'undefined' && newValue !== data[field]) {
                data[field] = newValue;
                SaveUserData(data);
            }
    }
}



const openModal = (data, field) => {
    state.openmodal = true;
    state.currentDataSet = data;
}

const getModalStatus = val => {
    state.openmodal = val;
}

const DeleteUserRow = val => {
    state.openmodal = val;
    if (val === true) {
        //delete local
        Simplestore.spinner = true;
        const payload = { user_id: state.currentDataSet.user_id }
        DeleteUser(payload).then(res => {
            users.value = users.value.filter(val => val.user_id !== state.currentDataSet.user_id);
            state.message.show = false;
            Simplestore.spinner = false;
            state.message.show = true;
            state.message.message = `User ${state.currentDataSet.user_lastname} wurde gelöscht`;
            state.message.messagemode = 'success';

            window.setTimeout(() => {
                state.message.show = false;
            }, 3000)
        });
    }
}


const SaveUserData = async data => {

    const payload =
    {
        "user_id": data.user_id,
        "user_email": data.user_email,
        "user_firstname": data.user_firstname,
        "user_lastname": data.user_lastname,
        "user_mobile": data.user_mobile ? data.user_mobile : '',
        "user_active": true,
        "user_channel": data.user_channel,
        "user_roles": data.user_roles,
        "user_street": data.user_street ? data.user_street : '',
        "user_land": data.user_land,
        "user_city": data.user_city ? data.user_city : '',
        "user_plz": data.user_plz ? data.user_plz : ''
    }
    console.log(payload);

    if (payload.user_email) {

        state.spinner = true;
        Simplestore.spinner = true;
        await axios
            .post("/dashboard/edit-user-admin", payload)
            .then((response) => {

                if (response.data === false) {
                    Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                    state.spinner = false;
                    Simplestore.spinner = false;

                } else {

                    if (response.data.message && response.data.message === 'error') {
                        Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                        state.spinner = false;
                        Simplestore.spinner = false;
                    } else {
                        //localStorage.setItem(process.env.VUE_APP_NEURAXIS_TOKENNAME, response.data.jwt);
                        state.spinner = false;
                        state.message.messagemode = 'success';
                        state.message.message = 'Ihre Daten wurden erfolgreich gespeichert';
                        state.message.show = true;
                        Simplestore.spinner = false;
                        window.setTimeout(() => {
                            state.message.show = false;
                        }, 3000)
                    }
                }

            })
            .catch(function (error) {
                state.message.show = true;
                //ErrorLog(error);
                window.setTimeout(() => {
                    state.spinner = false
                    state.message.show = false;
                    Simplestore.spinner = false;
                }, 3000)


            })
    }

}

</script>