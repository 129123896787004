<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber';
import Toast from 'primevue/toast'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import Avatar from 'primevue/avatar';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import Toolbar from 'primevue/toolbar'

import MainPrimevue from '@/core/components/UI/Main-Primevue.vue';
import MainGenPasswd from '@/core/components/Main-GenPasswd.vue';


const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const filter_results = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

const neuraxis = ref()
const channel = ref(null)
const type = ref(null)
const types = ref()
const channels = ref([])
const database = ref("neuraxis")
const index = ref()
const results = ref([])
const neuraxis_code = ref("")
const bob_id = ref()
const bob_content = ref()
const databases = ref([{ "name": "neuraxis" }])
const editBobDialog = ref(false)
const newBobDialog = ref(false)
const newPasswordGen = ref(false)
const newIndexDialog = ref(false)
const editIndexDialog = ref(false)
const json_error = ref()
const index_language = ref()
const index_name = ref()
const indexes = ref([])
const index_detail = ref()
const delete_index_activator = ref(false)
const fulltext = ref()
const json_path = ref()
const json_value = ref()
const bob_id_new = ref()



const index_fields = ref([
    {
        "name": "<fieldname>",
        "path": "$.<fieldname>",
        "type": "TEXT",
        "sortable": false,
        "phonetic": false,
        "weight": 1.0
    }
])

const index_languages = ref([
    {
        "value": "English"
    },
    {
        "value": "German"
    }
])

const index_field_types = ref([
    {
        "value": "TEXT"
    },
    {
        "value": "NUMERIC"
    },
    {
        "value": "GEO"
    },
    {
        "value": "TAG"
    },
    {
        "value": "VECTOR"
    }
])

onMounted(() => {
    loader.value
    let payload = {
        "channel": "neuraxis",
        "type": "system",
        "id": null
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/get-single-bob", payload)
        .then(response => {
            //console.log("response", response.data)
            neuraxis.value = response.data
            channels.value = response.data.channels
            payload = {
                "channel": "",
                "type": "",
                "id": null
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/dropdown-query", payload)
                .then(response => {
                    results.value = response.data
                    loader.value = false
                    neuraxis_code.value = "get_bobs(neuraxis)"
                })
        })
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/get-indexes")
        .then(response => {
            indexes.value = response.data
        })
})

const edit_bob = (id) => {
    loader.value = true
    bob_id.value = id
    let payload = {
        "id": id
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/get-single-bob-id", payload)
        .then(response => {
            bob_content.value = JSON.stringify(response.data, null, "\t")
            bob_id_new.value = id
            editBobDialog.value = true
            loader.value = false
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}

const save_edit_bob = (id) => {
    loader.value = true
    let payload = {
        "id": id,
        "new_id": bob_id_new.value,
        "content": JSON.parse(bob_content.value)
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/save-single-bob-id", payload)
        .then(response => {
            editBobDialog.value = false
            loader.value = false
            toast.add({ severity: 'success', summary: 'Success', detail: 'Bob Saved', life: 3000 });
            neuraxis_code.value = "crupdate_bob(neuraxis, '" + id + "', {...})"
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}

const new_bob = () => {
    bob_content.value = ""
    bob_id.value = null
    newBobDialog.value = true
}

const save_new_bob = () => {
    loader.value = true
    let id = ""
    if (bob_id.value == null) {
        id = channel.value + ":" + type.value
    }
    else {
        id = channel.value + ":" + type.value + ":" + bob_id.value
    }
    let payload = {
        "id": id,
        "content": JSON.parse(bob_content.value)
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/save-single-bob-id", payload)
        .then(response => {
            newBobDialog.value = false
            loader.value = false
            toast.add({ severity: 'success', summary: 'Success', detail: 'Bob Saved', life: 3000 });
            neuraxis_code.value = "crupdate_bob(neuraxis, '" + id + "', {...})"
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}

const new_index = () => {
    newIndexDialog.value = true
}

const add_index_field = () => {
    index_fields.value.push({
        "name": "<fieldname>",
        "path": "$.<fieldname>",
        "type": "TEXT",
        "sortable": false,
        "phonetic": false,
        "weight": 1.0
    })
}

const save_new_index = () => {
    loader.value = true
    let payload = {
        "channel": channel.value,
        "type": type.value,
        "index_name": index_name.value,
        "language": index_language.value,
        "fields": index_fields.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/create-index", payload)
        .then(response => {
            newIndexDialog.value = false
            loader.value = false
            toast.add({ severity: 'success', summary: 'Success', detail: 'Index Created', life: 3000 });
        }).catch(error => {
            console.error("There was an error!", error.message)
        });
}

const onIndexFieldSave = (event) => {
    index_fields.value[event.index][event.field] = event.newValue
    toast.add({ severity: 'success', summary: 'Changed Field:', detail: event.field + " to " + event.newValue, life: 3000 })
}

const onTextFieldRowReorder = (event) => {
    index_fields.value = event.value
    toast.add({ severity: 'success', summary: 'Reordered Fields', life: 3000 })
}

const edit_index = () => {
    loader.value = true
    delete_index_activator.value = false
    let payload = {
        "index_name": index.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/index-info", payload)
        .then(response => {
            index_detail.value = response.data
            editIndexDialog.value = true
            loader.value = false
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}

const delete_index = () => {
    editIndexDialog.value = false
    confirm.require({
        message: 'Are you sure you want to delete index: ' + index.value + '?',
        header: 'Delete Index',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete it',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No, keep it',
        accept: () => {
            loader.value = true
            let payload = {
                "index_name": index.value
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/delete-index", payload)
                .then(response => {
                    loader.value = false
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Index ' + index.value + ' Deleted', life: 3000 });
                }).catch(error => {
                    console.error("There was an error!", error.message);
                });
        }
    });
}

const delete_bob = (id) => {
    editIndexDialog.value = false
    confirm.require({
        message: 'Are you sure you want to delete bob: ' + id + '?',
        header: 'Delete Bob',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete it',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No, keep it',
        accept: () => {
            loader.value = true
            let payload = {
                "id": id
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/delete-single-bob", payload)
                .then(response => {
                    loader.value = false
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Bob ' + id + ' Deleted', life: 3000 });
                }).catch(error => {
                    console.error("There was an error!", error.message);
                });
        }
    });
}

const fulltext_search = () => {
    loader.value = true
    let payload = {
        "index_name": index.value,
        "fulltext_search": fulltext.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/fulltext-search", payload)
        .then(response => {
            results.value = response.data
            loader.value = false
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}

const json_search = () => {
    loader.value = true
    let payload = {
        "index_name": index.value,
        "json_path": json_path.value,
        "json_value": json_value.value
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/json-search", payload)
        .then(response => {
            results.value = response.data
            loader.value = false
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}

watch(bob_content, () => {
    if (isValidJSONString(bob_content.value)) {
        json_error.value = "";
    }
    else {
        json_error.value = "Syntax Error";
    }
})

watch([channel, type], async () => {
    loader.value = true
    let payload = {
        "channel": channel.value,
        "type": type.value,
        "id": null
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahub/dropdown-query", payload)
        .then(response => {
            results.value = response.data
            loader.value = false
            if (channel.value == null) {
                type.value = null
                neuraxis_code.value = "get_bobs(neuraxis)"
            }
            else if (type.value == null) {
                neuraxis_code.value = "get_bobs(neuraxis, '" + channel.value + "')"
            }
            else {
                neuraxis_code.value = "get_bobs(neuraxis, '" + channel.value + ":" + type.value + "')"
            }
        })
    // get current types
    if (channel.value) {
        for (let key in channels.value) {
            if (channels.value[key]["prefix"] == channel.value) {
                types.value = channels.value[key]["types"]
            }
        }
    }
})

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.add({ severity: 'success', summary: 'Copied to clipboard', detail: text, life: 3000 });
}

const isValidJSONString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const formatKeyName = (str) => {
    const keyArray = str.split(":");
    let id = ""
    if (keyArray[2]) {
        id = "<span style='color:#D32F2F'>:</span><span>" + keyArray[2] + "</span>"
    }
    if (keyArray[3]) {
        id = "<span style='color:#D32F2F'>:</span><span>" + keyArray[2] + "</span><span style='color:#000000'>:</span><span>" + keyArray[3] + "</span>"
    }
    if (keyArray[4]) {
        id = "<span style='color:#D32F2F'>:</span><span>" + keyArray[2] + "</span><span style='color:#000000'>:</span><span>" + keyArray[3] + "</span>" + "<span style='color:#000000'>:</span><span>" + keyArray[4] + "</span>"
    }
    return "<span style='color:#1c80cf'>" + keyArray[0] + "</span><span style='color:#419544'>:</span><span style='color:#2a602c'>" + keyArray[1] + "</span>" + id
}


</script>


<template>
    <div class="p-5">
        <Toast />
        <ConfirmDialog></ConfirmDialog>
        <div>
            <Toolbar class="my-3">
                <template #start>
                    <RouterLink to="/dashboard"><Button label="Zurück zum Dashboard" icon="pi pi-angle-double-left" class="mr-2" /></RouterLink>
                </template>
            </Toolbar>
        </div>

        <Toolbar>
            <template #start>
                <Dropdown placeholder="Select Database" v-model="database" :options="databases" optionLabel="name" optionValue="name" class="w-auto mr-4" />
                <Dropdown placeholder="Select Channel" v-model="channel" :options="channels" optionLabel="name" optionValue="prefix" class="w-auto mr-2" :showClear="true" />
                <Button @click="edit_bob('neuraxis:system')" class="mr-2 p-button" v-tooltip.bottom="'Edit Channel'" icon="pi pi-pencil" />
                <Dropdown :disabled="channel ? false : true" placeholder="Select Type" v-model="type" :options="types" optionLabel="prefix" optionValue="prefix" class="w-auto" :showClear="true" />
                <Divider layout="vertical" />
                <Button @click="new_bob()" class="p-button" v-tooltip.bottom="'Add Bob'" icon="pi pi-plus" />
                <Button @click="newPasswordGen = true" class="p-button ml-2" v-tooltip.bottom="'Password Generator'" icon="pi pi-key" />
            </template>
        </Toolbar>
        <Toolbar class="mt-2">
            <template #start>
                <Dropdown placeholder="Select Index" v-model="index" :options="indexes" optionLabel="id" optionValue="id" class="w-auto mr-2" />
                <Button @click="edit_index()" :disabled="index ? false : true" class="mr-2 p-button" v-tooltip.bottom="'Edit Customer'" icon="pi pi-pencil" />
                <Button @click="new_index()" class="p-button" v-tooltip.bottom="'Create Index'" icon="pi pi-plus" />
                <Divider layout="vertical" />
                <span class="p-input-icon-left mr-2" style="width: 200px">
                    <i class="pi pi-database" />
                    <InputText :disabled="index ? false : true" v-model="json_path" placeholder="JSON Field" />
                </span>
                <span class="p-input-icon-left mr-2" style="width: 200px">
                    <i class="pi pi-database" />
                    <InputText :disabled="index ? false : true" v-model="json_value" placeholder="JSON Value" />
                </span>
                <Button :disabled="!index ? true : !json_path ? true : !json_value ? true : false" @click="json_search()" label="JSON Search" class="w-auto p-button-danger" v-tooltip.top="'Redis JSON Search'" icon="pi pi-search" />
                <Divider layout="vertical" />
                <span class="p-input-icon-left mr-2">
                    <i class="pi pi-database" />
                    <InputText :disabled="index ? false : true" v-model="fulltext" placeholder="Redis Fulltext Search" />
                </span>
                <Button :disabled="!index ? true : !fulltext ? true : false" @click="fulltext_search()" label="Fulltext Search" class="w-auto mr-2 p-button-danger" v-tooltip.top="'Redis Fulltext Search'" icon="pi pi-search" />

            </template>
        </Toolbar>
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left" style="width: 400px">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_results['global'].value" placeholder="Search results" />
                </span>
                <Divider layout="vertical" />
                <span class="p-input-icon-left" style="width: 400px">
                    <i class="pi pi-code" style="color:aquamarine" />
                    <InputText style="background-color: #252526; color:#CCA700" v-model="neuraxis_code" />
                </span>
                <Button @click="copy_to_clipboard(neuraxis_code)" v-tooltip.top="'Copy to clipboard'" class="p-button p-button-sm p-button-text" icon="pi pi-copy" />
                <Divider layout="vertical" />
                <span class="font-bold mr-1">redis-py</span><span><a href="https://redis.readthedocs.io/" target="_blank">docs</a> • <a href="https://redis.readthedocs.io/" target="_blank">src</a></span>
            </template>
        </Toolbar>

        <!--------------------------------------------------->
        <!------------------ DataTable ---------------------->
        <!--------------------------------------------------->

        <DataTable v-model:filters="filter_results" class="mt-3" :value="results" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="id" header="Key" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-key" class="mr-2" style="background-color:#D32F2F; color: #ffffff" shape="circle" />
                    <span class="font-bold" v-html="formatKeyName(slotProps.data.id)"></span>
                    <Button @click="copy_to_clipboard(slotProps.data.id)" v-tooltip.top="'Copy to clipboard'" class="p-button p-button-sm p-button-text" icon="pi pi-copy" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button @click="edit_bob(slotProps.data.id)" v-tooltip.top="'Edit'" class="p-button p-button-sm p-button-text" icon="pi pi-pencil" />
                    <Button @click="delete_bob(slotProps.data.id)" v-tooltip.top="'Delete'" class="p-button p-button-sm p-button-text" icon="pi pi-trash" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Bob ---------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="editBobDialog" :style="{ width: '80%' }" header="Edit Bob" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-4 mt-3">
                <span class="p-float-label">
                    <InputText id="bob_id" type="text" v-model="bob_id_new" />
                    <label for="bob_id">ID</label>
                </span>
            </div>
                        <div class="field col-4 mt-3 p-2">
                    <MainGenPasswd />
                </div>
            <div class="field col-12 mt-3">
                <Textarea id="bob_content" :autoResize="true" rows="12" v-model="bob_content" style="background-color: #1e1e1e; color:#ffebcd" />
                <Message v-if="json_error" severity="warn" :closable="false">{{ json_error }}</Message>
            </div>
            <div class="field col-12 mt-1">
                <Button :disabled="json_error ? true : false" label="Save" @click="save_edit_bob(bob_id)" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Cancel" @click="editBobDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>

    <!--------------------------------------------------->
    <!------------------ Create Bob --------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="newBobDialog" :style="{ width: '80%' }" header="Create Bob" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-4 mt-3">
                <Dropdown placeholder="Select Channel" v-model="channel" :options="channels" optionLabel="name" optionValue="prefix" class="w-auto mr-2" :showClear="true" />
            </div>
            <div class="field col-4 mt-3">
                <Dropdown placeholder="Select Type" v-model="type" :options="types" optionLabel="prefix" optionValue="prefix" class="w-auto mr-2" :showClear="true" />
            </div>
            <div class="field col-4 mt-3">
                <span class="p-float-label">
                    <InputText id="bob_id" type="text" v-model="bob_id" />
                    <label for="bob_id">ID (optional)</label>
                </span>
            </div>
            <div class="field col-4 mt-3 p-2">
                <MainGenPasswd />
            </div>
            <div class="field col-12 mt-3">
                <Textarea id="bob" :autoResize="true" rows="12" v-model="bob_content" style="background-color: #1e1e1e; color:#ffebcd" />
                <Message v-if="json_error" severity="warn" :closable="false">{{ json_error }}</Message>
                <Message v-if="!channel" severity="warn" :closable="false">Please select Channel</Message>
                <Message v-if="!type" severity="warn" :closable="false">Please select Type</Message>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button :disabled="json_error ? true : !channel ? true : !type ? true : false" label="Save" @click="save_new_bob()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Cancel" @click="newBobDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>

    </Dialog>

    <Dialog v-model:visible="newPasswordGen" :style="{ width: '80%' }" header="Generate PW" :modal="true" class="p-fluid">
        <div class="p-5">
            <MainGenPasswd />
        </div>
        <Button label="Close" @click="newPasswordGen = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
    </Dialog>


    <!--------------------------------------------------->
    <!----------------- Create Index -------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="newIndexDialog" :style="{ width: '1050px' }" header="Create Index" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-4 mt-3">
                <Dropdown placeholder="Select Channel" v-model="channel" :options="channels" optionLabel="name" optionValue="prefix" class="w-auto" :showClear="true" />
            </div>
            <div class="field col-4 mt-3">
                <Dropdown placeholder="Select Type" v-model="type" :options="types" optionLabel="prefix" optionValue="prefix" class="w-auto" :showClear="true" />
            </div>
            <div class="field col-4 mt-3">
                <span class="p-float-label">
                    <InputText id="index_name" type="text" v-model="index_name" />
                    <label for="index_name">Index Name (optional)</label>
                </span>
            </div>
            <div class="field col-4 mt-3">
                <span class="p-float-label">
                    <Dropdown id="languages" v-model="index_language" :options="index_languages" optionLabel="value" optionValue="value" class="w-auto" />
                    <label for="languages">Language</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <DataTable style="border: 1px solid #ced4da; border-radius: 3px;" :value="index_fields" editMode="cell" @cell-edit-complete="onIndexFieldSave" @rowReorder="onTextFieldRowReorder" responsiveLayout="scroll">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="name" header="Name">
                        <template #editor="{ data, field }">
                            <InputText id="field_name" type="text" v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column field="path" header="Path">
                        <template #editor="{ data, field }">
                            <InputText id="field_path" type="text" v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column field="type" header="Type">
                        <template #editor="{ data, field }">
                            <Dropdown id="field_type" v-model="data[field]" :options="index_field_types" optionLabel="value" optionValue="value" class="w-auto mr-2" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                    <Column field="sortable" header="Sortable">
                        <template #editor="{ data, field }">
                            <InputSwitch v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <InputSwitch v-model="data[field]" />
                        </template>
                    </Column>
                    <Column field="phonetic" header="Phonetic">
                        <template #editor="{ data, field }">
                            <InputSwitch v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <InputSwitch v-model="data[field]" />
                        </template>
                    </Column>
                    <Column field="weight" header="Weight">
                        <template #editor="{ data, field }">
                            <InputNumber id="field_weight" v-model="data[field]" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" showButtons :step="0.50" />
                        </template>
                        <template #body="{ data, field }">
                            <span>{{ data[field] }}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Add Field" @click="add_index_field()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-plus" />
            </div>
            <div class="field col-12 mt-3">
                <span class="font-bold mr-1">redis create index documentation:</span> <span><a href="https://redis.io/commands/ft.create/" target="_blank">ft.create</a></span>
                <Message v-if="!channel" severity="warn" :closable="false">Please select Channel</Message>
                <Message v-if="!type" severity="warn" :closable="false">Please select Type</Message>
                <Message v-if="!index_language" severity="warn" :closable="false">Please select Language</Message>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button :disabled="!index_language ? true : !channel ? true : !type ? true : false" label="Save" @click="save_new_index()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                    <Button label="Cancel" @click="newIndexDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>

    <!--------------------------------------------------->
    <!------------------ Edit Index --------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="editIndexDialog" :style="{ width: '80%' }" header="Index Details" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <InputSwitch id="delete_index_activator" v-model="delete_index_activator" class="mr-2 mb-1" />
                <Button :disabled="delete_index_activator ? false : true" label="Delete Index" @click="delete_index()" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div class="field col-12 mt-3">
                <table style="border-collapse: collapse; border: 1px solid #ced4da;">
                    <tbody>
                        <tr v-for="bob_fields, key in index_detail" :key="key">
                            <td class="font-bold p-2" style="background-color: #f8f9fa; border: 1px solid #ced4da;">{{ key }}</td>
                            <td v-if="String(key) == 'attributes'" class="p-2" style="border: 1px solid #ced4da;">
                                <div v-for="identifier, key in bob_fields" :key="key" class="mt-2">
                                    {{ identifier }}
                                </div>
                            </td>
                            <td v-else class="p-2" style="border: 1px solid #ced4da;">{{ bob_fields }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Button label="Cancel" @click="editIndexDialog = false" class="w-auto mr-2 mb-1 p-button-danger" icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
</template>